import axios from 'axios';

let prefix = "/car-add-on"

const CarAddOnApi = {
	
	getCarAddOnList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	updateCarAddOn(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default CarAddOnApi;