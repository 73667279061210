import axios from 'axios';

let prefix = "/report"

const ReportApi = {
	
	downloadReport(payload){
		return axios.get( prefix + "/download", {params: payload})
	},

	listReportRef(payload){
		return axios.get( prefix + "/list-ref", {params: payload})
	},
}

export default ReportApi;