import axios from 'axios';

let prefix = "/news"

const NewsApi = {
	
	getNewsList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getNewsById(payload){
		return axios.get( prefix + "/get-by-id", {params: payload})
	},
	listNewsImage(payload){
		return axios.get( prefix + "/list-image", {params: payload})
	},
	archiveNews(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createNews(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateNews(payload){
		return axios.post( prefix + "/update", payload)
	},
	setNewsImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearNewsImage(payload){
		return axios.delete( prefix + "/delete-image", {params: payload})
	},
}

export default NewsApi;