import axios from 'axios';

let prefix = "/car-model-color"

const CarModelColorApi = {
	
	getCarModelColorList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getCarModelColor(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	archiveCarModelColor(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createCarModelColor(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateCarModelColor(payload){
		return axios.post( prefix + "/update", payload)
	},
	setCarModelColorImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearCarModelColorImage(payload){
		return axios.delete( prefix + "/delete-image", {params: payload})
	},
	bulkUploadImage360(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload-image-360", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearCarModelColorImage360(payload){
		return axios.delete( prefix + "/delete-image-360", {params: payload})
	},
}

export default CarModelColorApi;