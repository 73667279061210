import axios from 'axios';

let prefix = "/faq"

const FaqApi = {
	
	getFaqList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getFaqById(payload){
		return axios.get( prefix + "/get-by-id", {params: payload})
	},
	archiveFaq(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createFaq(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateFaq(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default FaqApi;