import axios from 'axios';

let prefix = "/outlet"

const OutletApi = {
	
	getOutletList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getOutletStateList(payload){
		return axios.get( prefix + "/list-ref", {params: payload})
	},
	archiveOutlet(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
	createOutlet(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateOutlet(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default OutletApi;