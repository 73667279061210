import axios from 'axios';

let prefix = "/supportive"

const SupportiveApi = {
	
	getSupportive(type, payload){
		return axios.get( prefix + "/get-admin/" + type, {params: payload})
	},
	createSupportive(payload) {
		return axios.post(prefix + "/create", payload)
	},
	updateSupportive(payload){
		return axios.post( prefix + "/update", payload)
	},
	archiveSupportive(payload) {
		return axios.delete(prefix + "/delete", { params: payload })
	},
}

export default SupportiveApi;