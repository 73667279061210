import axios from 'axios';

let prefix = "/subscription"

const SubscriptionApi = {
	
	getSubscriptionPerformance(payload){
		return axios.get( prefix + "/subscription-performance", {params: payload})
	},
	listSubscription(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getSubscription(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	getSubscriptionConfig(payload){
		return axios.get( prefix + "/subscription-config", {params: payload})
	},
}

export default SubscriptionApi;