import axios from 'axios';

let prefix = "/role"

const RoleApi = {
	
	getRoleList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listRoleRef(payload){
		return axios.get( prefix + "/list-ref", {params: payload})
	},
	archiveRole(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
	createRole(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateRole(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default RoleApi;