import axios from 'axios';

let prefix = "/page-content"

const PageContentApi = {
	
	getPageContentList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	archivePageContent(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createPageContent(payload){
		return axios.post( prefix + "/create", payload)
	},
	updatePageContent(payload){
		return axios.post( prefix + "/update", payload)
	},
	setPageContentImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearPageContentImage(payload){
		return axios.delete( prefix + "/delete-image", {params: payload})
	},
}

export default PageContentApi;