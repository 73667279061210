import axios from 'axios';

let prefix = "/system-variable"

const SystemVariableApi = {
	
	listSystemVariable(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

	getSecuritySetting(payload){
		return axios.get( prefix + "/security-setting", {params: payload})
	},

	updateSecuritySetting(payload){
		return axios.post( prefix + "/update-security-setting", payload)
	},

	getPasswordSetting(payload){
		return axios.get( prefix + "/password-setting", {params: payload})
	},

	updatePasswordSetting(payload){
		return axios.post( prefix + "/update-password-setting", payload)
	},

	getDownloadUrl(payload){
		return axios.get( prefix + "/download-link", payload)
	},

	getContactUsSetting(payload){
		return axios.get( prefix + "/get-contact-us-admin", {params: payload})
	},

	updateContactUsSetting(payload){
		return axios.post( prefix + "/update-contact-us-setting", payload)
	},

	updateMostEconomicalCar(payload){
		return axios.post( prefix + "/update-most-economical-car", payload)
	},

	updateMostPopularCarPackage(payload){
		return axios.post( prefix + "/update-most-popular-car-package", payload)
	},

	getEmailReceiverSetting(payload){
		return axios.get( prefix + "/get-email", {params: payload})
	},

	updateEmailReceiverSetting(payload){
		return axios.post( prefix + "/update-email-setting", payload)
	},

	getUsedCarModelSetting(payload){
		return axios.get( prefix + "/get-used-car-model", {params: payload})
	},

	updateUsedCarModelSetting(payload){
		return axios.post( prefix + "/update-used-car-model-setting", payload)
	},

}

export default SystemVariableApi;